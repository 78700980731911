@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'src/assets/colors/wine.scss';

.tm_wineDark {

  $primary:   mat.define-palette($mat-winepalette, 400);
  $accent:    mat.define-palette(mat.$amber-palette, A200, A100, A400);
  $warn:      mat.define-palette(mat.$red-palette);
  $themeDark: mat.define-dark-theme($primary, $accent, $warn);

  // Super Cores Maneiras
  .colorPrimary {color: mat.get-color-from-palette($primary); fill: mat.get-color-from-palette($primary); background-color: mat.get-color-from-palette($primary, default-contrast);}
  .colorAccent {color: mat.get-color-from-palette($accent); fill: mat.get-color-from-palette($accent); background-color: mat.get-color-from-palette($accent, default-contrast);}
  .colorWarn {color: mat.get-color-from-palette($warn);  fill: mat.get-color-from-palette($warn); background-color: mat.get-color-from-palette($warn, default-contrast);}
  .backgroundCorPrimary {background-color: mat.get-color-from-palette($primary); color: mat.get-color-from-palette($primary, default-contrast);}
  .backgroundCorAccent {background-color: mat.get-color-from-palette($accent); color: mat.get-color-from-palette($accent, default-contrast);}
  .backgroundCorWarn {background-color: mat.get-color-from-palette($warn); color: mat.get-color-from-palette($warn, default-contrast);}
  .backGroundSidenav {
    background: linear-gradient(mat.get-color-from-palette($primary, 400), mat.get-color-from-palette($primary, default, 65%), mat.get-color-from-palette($primary, default, 65%));
    height: 100%;
  }
  .colorSidenav {
    color:mat.get-color-from-palette($primary, default-contrast) !important;
  }
  @include mat.all-component-themes($themeDark);
}